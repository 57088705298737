import * as React from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setReferer} from "../../reducers/loginSlice";
import TopNav from "./TopNav";

const StudioHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return(
        <header>
            <TopNav />
            <div className="overlay"></div>
            <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                <source src="/video/graxle_studio.mp4" type="video/mp4" />
            </video>


            <div className="container h-100">
                <section className="pb-8" id="home">
                    <div className="container-small hero-header-container px-lg-7 px-3">
                        <div className="align-items-center ">
                            <div className="text-end order-0 order-md-1 order-1 col-lg-auto col-12">

                                <div className="hero-image-container position-absolute top-0 bottom-0 end-0 d-none d-lg-block">
                                    <div className="position-relative h-100 w-100">
                                        <div className="position-absolute h-100 top-0 d-flex align-items-center end-0 hero-image-container-bg"></div>

                                    </div>
                                </div>
                            </div>
                            <div className="text-lg-start text-center pb-6 order-0 position-relative col-lg-6 col-12">
                                <div className="text-white">
                                    <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-2 fs-xl-1 fw-black mb-4">Unleash your creativity with Graxle Studio</h1>
                                    <p className="mb-5 pe-xl-10">Harness the power of open-source diffusion models to bring your ideas to life with stunning AI generated images
                                    </p>
                                    <button tabIndex="0" onClick={() => {
                                        dispatch(setReferer({
                                            referer: "/studio"
                                        }));
                                        navigate("/auth?cta=register");
                                    }} type="button" className="rounded-pill me-3 btn btn-outline-light btn-lg">Try Studio for free</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </header>
    )
}

export default StudioHeader;
