import {createSlice} from '@reduxjs/toolkit';
import {jwtDecode as jwt_decode} from 'jwt-decode';

const userSlice = createSlice({
  name: 'login',
  initialState: {
    token: localStorage.getItem('token'),
    user: localStorage.getItem('token') !== null ? jwt_decode(localStorage.getItem('token')): {},
    isAuthenticated: localStorage.getItem('token') !== null,
    reset_password_email: '',
    showDeleteConfirmation: false,
    referer: '',
  },
  reducers: {
    loginUser(state, action) {
      const {token, user} = action.payload;
      state.token = token;
      state.user = user;
      state.isAuthenticated = Object.keys(user).length !== 0;
      localStorage.setItem('token', token);
    },
    logoutUser(state) {
      state.token = '';
      state.user = {};
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
    setReferer(state, action) {
      const {referer} = action.payload;
      state.referer = referer;
    }
  },
});

export const {loginUser, logoutUser, setReferer} =
  userSlice.actions;
export default userSlice.reducer;
