import * as React from 'react';

const FounderLetter = () => {
    return (
        <section className="bg-body pt-15 pb-18" id="features">
        <div className="container-small px-lg-7 px-xxl-3">
            <div className="position-relative z-2">
                <div
                    className="align-items-center justify-content-between text-center text-lg-start mb-6 mb-lg-2 row">
                    <div className="order-lg-1 col-lg-5"><img className="feature-image mb-9 mb-lg-0 d-dark-none"
                                                              src="assets/img/studio-founder-letter-illustration.png"
                                                              alt=""/><img
                        className="feature-image mb-9 mb-lg-0 d-light-none"
                        src="/static/media/dark_23.5de4665089c5131f8007.png" alt=""/></div>
                    <div className="col-lg-6"><h6 className="text-primary mb-2 ls-2">A NOTE FROM OUR FOUNDER</h6><h3
                        className="fw-bolder mb-3">We're seeing a huge shift in the world of AI</h3>
                        <p>
                            Proprietary AI is becoming cheaper to use as major companies
                            open-source powerful models.
                            But the technical skills required to use open-source models keeps them out of reach for most people.

                            </p>
                            <p>
                                We believe in a future for society where AI is accessible and equitable to all.
                                That’s why we started Graxle. Graxle’s underlying technology makes building AI applications with open-source models fast and easy.
                                With Graxle, we’re on a mission to break down the barriers to AI.
                                To demonstrate Graxle’s capabilities, we’ll make at least one new use case accessible every couple of weeks.

                            </p>
                            <p>
                                Graxle Studio is our first release. With Studio anyone can generate stunning visuals with the latest open-source diffusion models.
                                Its interface lets you easily harness open-source models like Stable Diffusion on the web.
                            </p>

                            <p>
                                Our goals for studio are to
                            </p>
                            <ul>
                                <li>
                                    keep it simple while generating high-quality images with just a few clicks
                                </li>
                                <li>
                                    make it ideal for anyone –  designers, marketers, content creators
                                </li>
                                <li>
                                    make it available on both web and mobile
                                </li>
                                <li>
                                    make open source models available for image generation
                                </li>
                            </ul>
                            <p>
                                You can sign up to use Graxle Studio right away. Take it for a spin and let us know what you think.
                                Thank you for joining us on the journey to make AI accessible to everyone.
                            </p>
                            <p>
                                Sincerely,
                            </p>
                            <p className="p-0 m-0">
                                Chinedu Chukuigwe
                            </p>
                            <p className="p-0 m-0">
                                Founder & CEO, Graxle, Inc
                            </p>
                            <p className="p-0 m-0">
                                chinedu@graxle.com
                            </p>

                            <a className="me-2 p-0 fs-9 fw-bold" href="/auth?cta=register">Sign up now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FounderLetter;
