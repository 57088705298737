import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Auth from "./Auth";
import StudioLanding from "./components/Marketing/StudioLanding";
import Terms from "./Terms";
import Marketing from "./components/Marketing";
import Tools from "./components/Tools";
import Studio from "./components/Tools/Studio";
import Errors from "./components/Errors";
import Subscribe from "./components/Subscription/Subscribe";
import Subscription from "./components/Subscription";
import SubscriptionConfirmation from "./components/Subscription/SubscriptionConfirmation";
import UserSubscriptionDetails from "./components/Subscription/UserSubscriptionDetails";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Marketing />,
        errorElement: <Errors />,
        children: [
            { path: "/", element: <StudioLanding />},
        ]
    },
    {
        path: "/tools",
        element: <Tools />,
        children: [
            { path: "/tools", element: <Studio />},
            { path: "/tools/studio", element: <Studio /> },
        ]
    },
    {
        path: "/subscription",
        element: <Subscription />,
        children: [
            { path: "/subscription", element: <UserSubscriptionDetails /> },
            { path: "/subscription/subscribe", element: <Subscribe />},
            { path: "/subscription/confirmation", element: <SubscriptionConfirmation />},
        ]
    },
    {
        path: "/auth",
        element: <Auth />
    },
    {
        path: "/terms",
        element: <Terms />
    }
]);
const container = document.getElementById('top');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
