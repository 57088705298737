import {createSlice} from '@reduxjs/toolkit';

const subscribeSlice = createSlice({
    name: 'subscription',
    initialState: {
        plan: undefined,
        tool: undefined,
        options: {
            clientSecret: undefined
        },
        purchaseError: undefined
    },
    reducers: {
        kickstartSubscription(state, action) {
            const {plan, tool} = action.payload;
            state.plan = plan;
            state.tool = tool;
        },
        setOptions(state, action) {
            const {options} = action.payload;
            state.options = options;
        },
        setPurchaseError(state, action) {
            const {purchaseError} = action.payload;
            state.purchaseError = purchaseError;
        }
    },
});

export const {
    kickstartSubscription,
    setOptions,
    setPurchaseError,
} = subscribeSlice.actions;
export default subscribeSlice.reducer;
