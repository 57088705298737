import Modal from "react-bootstrap/Modal";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleShowImage} from "../../../reducers/studioSlice";

const ImageOverlay = () => {
    const {activePrompt, activeImage, showImage} = useSelector(state => state.studio);
    const dispatch = useDispatch();

    return (
        <>
            <Modal
                show={showImage}
                fullscreen={true}
                onHide={() => {
                    dispatch(toggleShowImage({showImage: false, activeImage: undefined, activePrompt: undefined}));
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{activePrompt}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={activeImage} alt="" />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImageOverlay;
