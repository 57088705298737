import * as React from "react";

const Terms = () => {
    return(
        <div className="container pt-6">
            <h1 className="pb-3">Terms & Conditions</h1>
            <h3 className="pb-3">General Terms of Service</h3>
            <h4>1. Acceptance of Terms</h4>
            <p>
                By downloading, installing, or using the Graxle platform ("App"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use the App.
            </p>
            <h4>2. Changes to Terms</h4>
            <p>
                We reserve the right to modify these Terms at any time. We will notify you of any changes by updating the date at the top of this page. Your continued use of the App after such modifications constitutes your acceptance of the new Terms.
            </p>
            <h4>3. Use of the App</h4>
            <p>
                You agree to use the App in accordance with these Terms and all applicable laws and regulations. You must not use the App for any unlawful or prohibited purpose.
            </p>
            <h4>
                4. User Accounts
            </h4>
            <p>
                To use certain features of the App, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
            </p>
            <h4>
                5. Privacy Policy
            </h4>
            <p>
                Your use of the App is also governed by our Privacy Policy, which is incorporated into these Terms by reference.
            </p>
            <h4>
                6. Intellectual Property
            </h4>
            <p>
                All content included in the App, such as text, graphics, logos, and software, is the property of Graxle, Inc or its licensors and is protected by copyright, trademark, and other laws.
            </p>
            <h4>
                7. Termination
            </h4>
            <p>
                We may terminate or suspend your access to the App at any time, without prior notice or liability, for any reason, including if you breach these Terms.
            </p>
            <h4>
                8. Disclaimer of Warranties
            </h4>
            <p>
                The App is provided "as is" and "as available" without warranties of any kind, either express or implied.
            </p>
            <h4>
                9. Limitation of Liability
            </h4>
            <p>
                In no event shall Graxle, Inc be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.
            </p>
            <h4>
                10. Governing Law
            </h4>
            <p>
                These Terms shall be governed and construed in accordance with the laws of Delaware, USA, without regard to its conflict of law provisions.
            </p>
            <h4>
                11. Contact Information
            </h4>
            <p>
                If you have any questions about these Terms, please contact us at support@graxle.com.
            </p>
            <h3 className="pb-3">Privacy Policy</h3>

            <h4>1. Introduction</h4>
            <p>
                This Privacy Policy explains how Graxle, Inc ("we," "our," or "us") collects, uses, and discloses information about you when you use our platform ("App").
            </p>
            <h4>
                2. Information We Collect
            </h4>
           <p>
               We may collect the following types of information:
           </p>
            <ul>
                <li>
                    Personal Information: Information that can be used to identify you, such as your name, email address, and phone number.
                </li>
                <li>
                    Usage Information: Information about your use of the App, such as your device information, log data, and location information.
                </li>
            </ul>
            <h4>
                3. How We Use Your Information
            </h4>
            <p>
                We may use the information we collect for various purposes, including to:
            </p>
            <ul>
                <li>
                    Provide, maintain, and improve the App
                </li>
                <li>
                    Communicate with you
                </li>
                <li>
                    Monitor and analyze trends and usage
                </li>
                <li>
                    Personalize your experience
                </li>
            </ul>
            <h4>
                4. Sharing of Information
            </h4>
            <p>
                We may share your information with third parties in the following circumstances:
            </p>
            <ul>
                <li>
                    With your consent
                </li>
                <li>
                    With service providers who perform services on our behalf
                </li>
                <li>
                    In connection with a merger, sale, or other transfer of assets
                </li>
                <li>
                    As required by law
                </li>
            </ul>
            <h4>
                5. Security
            </h4>
            <p>
                We take reasonable measures to help protect your information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
            </p>
            <h4>
                6. Data Retention
            </h4>
            <p>
                We will retain your information for as long as necessary to fulfill the purposes for which it was collected, or as required by law.
            </p>
            <h4>
                7. Your Choices
            </h4>
            <p>
                You may update or delete your account information at any time by accessing your account settings within the platform.
            </p>
            <h4>
                8. Children's Privacy
            </h4>
            <p>
                The App is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13.
            </p>
            <h4>
                9. Changes to this Privacy Policy
            </h4>
            <p>
                We may update this Privacy Policy from time to time. We will notify you of any changes by updating the date at the top of this page.
            </p>
            <h4>
                10. Contact Us
            </h4>
            <p>
                If you have any questions about this Privacy Policy, please contact us at support@graxle.com.
            </p>
        </div>
    )
}

export default Terms;
