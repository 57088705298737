import * as React from 'react';
import {useGetSubscriptionQuery} from "../../api/apiSlice";
import {useSelector} from "react-redux";
import Navbar from "../Tools/NavBar";
import PlansOverlay from "../Tools/Studio/PlansOverlay";
import {useOutletContext} from "react-router-dom";

const UserSubscriptionDetails = () => {
    const {token} = useSelector(state => state.login);
    const {promptStats} = useOutletContext();
    const {
        data: userSubscription,
        isLoading: isLoadingUserSubscription,
    } = useGetSubscriptionQuery({
        token: token,
    });

    const purchasedCredits = userSubscription?.reduce((acc, curr) => Number(acc) + Number(curr?.image_limit), 0);
    const totalCredits = purchasedCredits + 10;
    const creditsLeft = totalCredits - promptStats?.num_resources_created;

    return(
        <>
           <Navbar />
            {
                typeof userSubscription !== 'undefined' && !isLoadingUserSubscription && userSubscription?.length > 0 ? (
                    <div className="content container">
                        <h4 className="border-bottom border-light mt-3 mb-3 pb-3 text-muted">You have {creditsLeft} credits left. You can still generate {creditsLeft} images</h4>
                        <p className="mb-5 mt-5">
                            You have purchased a total of {purchasedCredits} credits. You receive 10 free credits on sign up. You have used {promptStats?.num_resources_created} credits so far.
                        </p>
                        {userSubscription?.map((subscription, index) => {
                            return (
                                <div className="col-xxl-3 col-sm-6 mt-5" key={index}>
                                    <div className="pricing-column-title-box mb-sm-5" style={{height: "30px"}}><h3
                                        className="mb-2">{subscription?.plan}</h3>
                                    </div>
                                    <div className="d-flex align-items-center mb-4"><h3
                                        className="display-3 fw-bolder">${subscription?.price}</h3></div>
                                    <h5 className="mb-4">What’s included</h5>
                                    <ul className="fa-ul" style={{faLiMargin: '1.5em'}}>
                                        <li className="text-body-secondary mb-2">
                                            <span className="fa-li">
                                                <svg aria-hidden="true"
                                                     focusable="false"
                                                     data-prefix="fas"
                                                     data-icon="check"
                                                     className="svg-inline--fa fa-check text-primary"
                                                     role="img"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 448 512">
                                                    <path fill="currentColor"
                                                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                                                </svg>
                                            </span>
                                            Generate {subscription?.image_limit} images, 1 credit per image
                                        </li>
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="content container">
                        {
                            isLoadingUserSubscription ? (
                                <p className="text-muted">Working...</p>
                            ) : (
                                <p className="text-muted">You haven't purchased any credits</p>
                            )
                        }
                    </div>
                )
            }
            <PlansOverlay />
        </>
    )
}

export default UserSubscriptionDetails;
