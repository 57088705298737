import * as React from "react";
import NavBar from "../NavBar";
import * as Icon from "react-bootstrap-icons";
import {Form} from "react-final-form";
import {
    useSavePromptMutation,
    useRunPredictionMutation,
    useGetPromptsQuery, useUpdatePromptMutation, useGetSubscriptionQuery, useGetPromptStatsByUserQuery
} from "../../../api/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import PromptForm from "./PromptForm";
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import {setBaseURL} from "../../../reducers/platformSlice";
import {AI_URL, API_URL, generateFileName} from "../../../constants";
import DownloadButton from "./DownloadButton";
import ImageOverlay from "./ImageOverlay";
import {toggleShowImage} from "../../../reducers/studioSlice";
import PlansOverlay from "./PlansOverlay";

const Studio = () => {
    const {token} = useSelector(state => state.login);
    const dispatch = useDispatch();
    const [savePrompt, savePromptResult] = useSavePromptMutation();
    const [runPrediction, runPredictionResult] = useRunPredictionMutation();
    const [updatePrompt, updatePromptResult] = useUpdatePromptMutation();
    const allowedModels = ["stable-diffusion-v1.5", "stable-diffusion-xl-base-1.0", "kandinsky-2.2"];
    const {
        data: userSubscription,
        isLoading: isLoadingUserSubscription,
    } = useGetSubscriptionQuery({
        token: token,
    });

    const {
        data: prompts,
        isLoading,
        refetch
    } = useGetPromptsQuery({"token": token});

    const {
        data: promptStats,
        isLoading: isLoadingPromptStats,
    } = useGetPromptStatsByUserQuery({
        "token": token,
    });

    const images = prompts?.filter(item => item.image_url !== undefined && item.image_url !== "");
    const purchasedCredits = userSubscription?.reduce((acc, curr) => Number(acc) + Number(curr?.image_limit), 0);
    const totalCredits = purchasedCredits + 10;
    const creditsLeft = totalCredits - promptStats?.num_resources_created;

    const submit = (values) => {
        if (values?.prompt && values?.model_id && values?.prompt.trim() !== "" && allowedModels.indexOf(values?.model_id) !== -1 ) {
            dispatch(setBaseURL({BASE_URL: API_URL}));
            savePrompt({
                token: token,
                prompt: values.prompt,
                model_id: values.model_id
            });
        }
    };

    const validate = values => {
        let errors = {};
        // validate prompt
        if (!values?.prompt || values?.prompt?.trim() === "") {
            errors.prompt = "You must enter a prompt."
        }
        // validate model
        if (!values?.model_id || values?.model_id?.trim() === "" || allowedModels.indexOf(values?.model_id) === -1) {
            errors.model_id = "You must select a valid model."
        }
        return errors;
    };

    // Call the prediction service when the prompt has been saved.
    React.useEffect(() => {
        if (savePromptResult?.status === 'fulfilled' && savePromptResult?.data?.uuid) {
            dispatch(setBaseURL({BASE_URL: AI_URL}));
            runPrediction({
                token: token,
                prompt: savePromptResult?.data?.prompt,
                model_id: savePromptResult?.data?.model_id
            });
        }
    }, [dispatch, runPrediction, savePromptResult, token]);

    React.useEffect(() => {
        if (runPredictionResult?.status === 'fulfilled' && runPredictionResult?.data?.location !== "") {
            dispatch(setBaseURL({BASE_URL: API_URL}));
            updatePrompt({
                token: token,
                uuid: savePromptResult?.data?.uuid,
                location: runPredictionResult?.data?.location
            });
            savePromptResult?.reset();
            runPredictionResult?.reset();
        }
    }, [dispatch, runPredictionResult, savePromptResult, token, updatePrompt]);

    React.useEffect(() => {
        if (updatePromptResult?.status === 'fulfilled') {
            refetch();
            updatePromptResult.reset();
        }
    }, [updatePromptResult, refetch]);

    return (
        <>
            <NavBar />
            <div className="content">
                <div className="chat d-flex gap-3">
                    <div className="h-100 w-100 card phoenix-offcanvas-container border-0"
                         style={{backgroundColor: '#0f111a'}}>
                        <div className="card-header border-0 p-0">
                            {
                                savePromptResult?.isLoading || runPredictionResult?.isLoading || updatePromptResult?.isLoading ? (
                                    <>
                                        Generating...
                                        <Spinner animation="border" variant="light"/>
                                    </>
                                ) : null
                            }
                            <Form
                                onSubmit={submit}
                                validate={validate}
                                initialValues={{model_id: 'stable-diffusion-v1.5'}}
                                render={({handleSubmit, form}) => (
                                    <>
                                        {
                                            !isLoadingUserSubscription && !isLoadingPromptStats && !isLoading && !Number.isNaN(creditsLeft) && creditsLeft > 0 ? (
                                                <>
                                                    <PromptForm
                                                        handleSubmit={handleSubmit}
                                                        form={form}
                                                        savePromptResult={savePromptResult}
                                                        runPredictionResult={runPredictionResult}
                                                        updatePromptResult={updatePromptResult}
                                                    />
                                                    <div className="mb-2 d-flex gap-2"/>
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <button
                                                            type="submit"
                                                            className="ms-auto btn btn-outline-light border-0"
                                                            disabled={savePromptResult?.isLoading || runPredictionResult?.isLoading || updatePromptResult?.isLoading}
                                                            onClick={() => {
                                                                submit(form.getState().values);
                                                            }}
                                                        >
                                                            <Icon.Send/> Generate
                                                        </button>
                                                    </div>
                                                </>
                                            ) : (
                                                creditsLeft <= 0
                                                &&
                                                <div className="alert alert-danger">
                                                    You have no more credits left. Please buy credits to continue
                                                    generating images.
                                                </div>
                                            )
                                        }
                                    </>
                                )}/>
                        </div>
                        <div className="p-3 p-sm-4 scrollbar d-flex flex-column gap-2 card-body">
                            {
                                isLoading && <p className="text-muted">Working...</p>
                            }
                            <div className="row row-cols-1 row-cols-md-2 g-4">
                                {
                                    images?.length > 0 ? (
                                        images?.map((item, index) => {
                                            const image_url = "https://cb-content-n1.imgix.net/" + item.image_url + "?w=410&h=250&fit=crop"
                                            const download_link = "https://cb-content-n1.imgix.net/" + item.image_url
                                            return (
                                                <div className="col mt-6" key={index} style={{
                                                    width: "410px",
                                                    height: "250px",
                                                    cursor: "pointer"
                                                }}
                                                     onClick={() => {
                                                         dispatch(toggleShowImage({
                                                             showImage: true,
                                                             activeImage: download_link,
                                                             activePrompt: item?.prompt
                                                         }))
                                                     }}
                                                >
                                                    <Card className="text-white overflow-hidden me-2">
                                                        <Card.Img variant="top" src={image_url}/>
                                                        <Card.ImgOverlay className="d-flex align-items-end">
                                                            <div>
                                                                <Card.Title as="h4" className="text-white">
                                                                    {item?.prompt}
                                                                </Card.Title>
                                                            </div>
                                                        </Card.ImgOverlay>
                                                    </Card>
                                                    <DownloadButton image_url={download_link}
                                                                    file_name={generateFileName(item.prompt)}/>
                                                </div>
                                            )
                                        })
                                    ) : null
                                }
                            </div>

                            <span/>
                        </div>
                    </div>
                </div>
                <div className=""/>
            </div>
            <ImageOverlay />
            <PlansOverlay current_plan={userSubscription?.length > 0 ? userSubscription[0]: undefined} />
        </>
    );
}

export default Studio;


