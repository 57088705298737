import * as React from 'react';
import * as Icon from "react-bootstrap-icons";

const DownloadButton = ({ image_url, file_name }) => {

    const downloadImage = async (url, name) => {
        try {
            // Fetch the image data
            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = name;
            document.body.appendChild(link);
            link.click();

            // Clean up by revoking the Object URL and removing the element
            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };


    // Use the async function in the event handler
    const handleDownload = () => {
        downloadImage(image_url, file_name);
    };

    return (
        <button className="btn btn-outline-light border-0" onClick={handleDownload}>
            <Icon.Download/>
        </button>
    );
};

export default DownloadButton;
