import * as React from 'react';
import {kickstartSubscription} from "../../reducers/subscriptionSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const Plan = ({plan}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return(
        <>
            <div className="col-xxl-3 col-sm-6">
                <div className="pricing-column-title-box mb-sm-5" style={{height: "50px"}}><h3
                    className="mb-2">{plan?.name}</h3>
                </div>
                <div className="d-flex align-items-center mb-4"><h3
                    className="display-3 fw-bolder">${plan?.price}</h3></div>
                <button type="button" className="w-100 mb-6 btn btn-outline-primary btn-lg" onClick={() => {
                    dispatch(kickstartSubscription({
                        plan: plan, tool: 'studio'
                    }));
                    navigate('/subscription/subscribe');
                }}>Buy
                </button>
                <h5 className="mb-4">What’s included</h5>
                <ul className="fa-ul" style={{faLiMargin: '1.5em'}}>
                    <li className="text-body-secondary mb-2">
                        <span className="fa-li">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="check"
                                className="svg-inline--fa fa-check text-primary"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path
                                    fill="currentColor"
                                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                />
                            </svg>
                        </span>Generate {plan?.image_limit} images, 1 credit per image
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Plan;
