import {configureStore} from '@reduxjs/toolkit';
import collectionReducer from './reducers/collectionSlice';
import appReducer from './reducers/appSlice';
import loginReducer from './reducers/loginSlice';
import datasetReducer from './reducers/datasets';
import errorReducer from './reducers/errorSlice';
import platformReducer from './reducers/platformSlice';
import studioReducer from './reducers/studioSlice';
import subscriptionReducer from "./reducers/subscriptionSlice";
import {apiSlice} from './api/apiSlice';

export const store = configureStore({
  reducer: {
      login: loginReducer,
      app: appReducer,
      error: errorReducer,
      collections: collectionReducer,
      datasets: datasetReducer,
      platform: platformReducer,
      studio: studioReducer,
      subscription: subscriptionReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: false,
      }).concat(apiSlice.middleware),
});
