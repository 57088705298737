import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {API_URL, APP_API_KEY} from '../constants';

const rawBaseQuery = fetchBaseQuery({ baseUrl: '' });

const dynamicBaseQuery = async (args, api, extraOptions) => {
    const BASE_URL = api.getState()?.platform?.BASE_URL;
    if (typeof BASE_URL !== "undefined" && BASE_URL !== "") {
      return rawBaseQuery({...args, url: BASE_URL + args.url}, api, extraOptions);
    }
    return rawBaseQuery({...args, url: API_URL + args.url}, api, extraOptions);
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: dynamicBaseQuery,
  endpoints: builder => ({
    joinWaitList: builder.mutation({
      query: data => ({
        url: 'users/wait_list',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          email: data.email ? data.email : ''
        },
      }),
    }),
    signUp: builder.mutation({
      query: data => ({
        url: 'users_wp8hti8i/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': APP_API_KEY,
        },
        body: {
          email: data.email ? data.email : '',
          first_name: data.first_name ? data.first_name : '',
          last_name: data.last_name ? data.last_name : '',
          password: data.password ? data.password : '',
          tokenize: true
        },
      }),
    }),
    // This request updates a user and tells the API to return a successful response as a JWT token
    updateUser: builder.mutation({
      query: data => ({
        url: 'users_wp8hti8i/' + data?.body?.uuid,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': APP_API_KEY,
          Authorization: 'Token ' + data?.token,
        },
        body: JSON.stringify(data?.body),
      }),
    }),
    isValidUser: builder.mutation({
      query: data => ({
        url: 'users_wp8hti8i/resource_exists_by_value',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': APP_API_KEY,
        },
        body: {
          value: data.email ? data.email : '',
          label: 'email',
          tokenize: true,
        },
      }),
    }),
    sendNotification: builder.mutation({
        query: data => ({
            url: 'notifications',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'X-API-KEY': APP_API_KEY,
            },
            body: {
              messages: data?.messages,
            },
        }),
    }),
    createApp: builder.mutation({
      query: data => ({
        url: typeof data?.values?.uuid === "undefined" ? 'apps': 'apps/' + data?.values?.uuid,
        method: typeof data?.values?.uuid === "undefined" ? "POST": "PUT",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          uuid: data?.values?.uuid ? data?.values?.uuid : '',
          name: data?.values?.name ? data?.values?.name : '',
          description: data?.values?.description ? data?.values?.description : '',
          regenerate_api_key: data?.values?.regenerate_api_key ? data?.values?.regenerate_api_key : false
        },
      }),
    }),
    deleteApp: builder.mutation({
      query: data => ({
        url: 'apps/' + data?.app?.uuid,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    savePrompt: builder.mutation({
      query: data => ({
        url: 'prompt_qe2k9l6i/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          model_id: data?.model_id ? data?.model_id : "",
          prompt: data?.prompt ? data?.prompt : "",
        },
      }),
    }),
    updatePrompt: builder.mutation({
        query: data => ({
          url: 'prompt_qe2k9l6i/' + data?.uuid,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: 'Token ' + data?.token,
            "X-Api-Key": APP_API_KEY,
          },
          body: {
            image_url: data?.location ? data?.location : "",
          },
        }),
    }),
    getPrompts: builder.query({
        query: data => ({
            url: 'prompt_qe2k9l6i/?start=1&limit=100',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Token ' + data?.token,
                "X-Api-Key": APP_API_KEY,
            },
        }),
    }),
    getPromptByID: builder.query({
        query: data => ({
            url: 'prompt_qe2k9l6i/' + data?.uuid,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              Authorization: 'Token ' + data?.token,
              "X-Api-Key": APP_API_KEY,
            },
        }),
    }),
    getPromptStatsByUser: builder.query({
      query: data => ({
        url: 'prompt_qe2k9l6i/stats_by_user?start_timestamp=0&end_timestamp=' + Date.now(),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    runPrediction: builder.mutation({
      query: data => ({
        url: 'inference',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          model_id: data?.model_id ? data?.model_id : "",
          prompt: data?.prompt ? data?.prompt : "",
        },
      }),
    }),
    fetchPaymentIntentClientSecret: builder.mutation({
      query: data => ({
        url: 'payment_intents',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
        body: {
          amount: data.amount ? data.amount : '',
          metadata: data.metadata ? data.metadata : {},
        },
      }),
    }),
    getPaymentIntent: builder.query({
      query: data => ({
        url: 'payment_intents?payment_intent_id=' + data?.paymentIntentId,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
      }),
    }),
    createSubscription: builder.mutation({
      query: data => ({
        url: 'subscriptions_zb8wol7y/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
        body: {
          plan: data?.plan,
          status: data?.status,
          image_limit: data?.image_limit,
          tool: data?.tool,
          start_date: data?.start_date,
          price: data?.price,
        }
      }),
    }),
    cancelSubscription: builder.mutation({
      query: data => ({
        url: 'subscriptions_zb8wol7y/' + data?.uuid,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
        body: {
          plan: data?.plan,
          status: data?.status,
          image_limit: data?.image_limit,
          tool: data?.tool,
          start_date: data?.start_date,
          end_date: data?.end_date,
          price: data?.price,
        }
      }),
    }),
    getSubscription: builder.query({
      query: data => ({
        url: 'subscriptions_zb8wol7y/?start=1&limit=100',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
      }),
    }),
    testPrompt: builder.mutation({
      query: data => ({
        url: "prompts/test_gpt3_prompt",
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          model: data?.values?.model ? data?.values?.model : "",
          temperature: data?.values?.temperature ? data?.values?.temperature : "",
          max_tokens: data?.values?.max_tokens ? data?.values?.max_tokens : "",
          top_p: data?.values?.top_p ? data?.values?.top_p : "",
          frequency_penalty: data?.values?.frequency_penalty ? data?.values?.frequency_penalty : "",
          presence_penalty: data?.values?.presence_penalty ? data?.values?.presence_penalty : "",
          prompt: data?.values?.prompt ? data?.values?.prompt : "",
        },
      }),
    }),
    createAPPJsonWithCopilot: builder.mutation({
      query: data => ({
        url: 'copilot',
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          description: data?.description
        },
      }),
    }),
    createCollection: builder.mutation({
      query: data => ({
        url: typeof data?.values?.uuid === "undefined" ? 'data_types': 'data_types/' + data?.values?.uuid,
        method: typeof data?.values?.uuid === "undefined" ? "POST": "PUT",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          app_id: data?.app?.uuid,
          uuid: data?.values?.uuid,
          name: data?.values?.name,
          description: data?.values?.description,
          created_by_self: data?.values?.created_by_self,
          authentication_required: data?.values?.authentication_required,
          endpoint: data?.values?.endpoint
        },
      }),
    }),
    deleteCollection: builder.mutation({
      query: data => ({
        url: 'data_types/' + data?.values?.uuid,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.body,
      }),
    }),
    createCollectionField: builder.mutation({
      query: data => ({
        url: typeof data?.values?.uuid === "undefined" ? 'data_types/' + data?.values?.collection?.uuid + '/fields': 'data_types/' + data?.values?.collection?.uuid + '/fields/' + data?.values?.uuid,
        method: typeof data?.values?.uuid === "undefined" ? "POST": "PUT",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.body,
      }),
    }),
    deleteCollectionField: builder.mutation({
      query: data => ({
        url: 'data_types/' + data?.values?.collection?.uuid + '/fields/' + data?.values?.uuid,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.body,
      }),
    }),
    startFileImport: builder.mutation({
      query: data => ({
        url: 'files/import_data',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          "data_type_id": data?.activeCollectionID,
          "file_id": data?.file_id,
          "app_id": data?.app_id,
        },
      }),
    }),
    saveFileData: builder.mutation({
      query: data => ({
        url: 'files',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.file,
      }),
    }),
    getDataTypeData: builder.query({
      query: data => ({
        url: 'data_types/data/' + data?.activeCollectionID +'?app_id=' + data?.app_id + '&start=' + data?.start + '&limit=' + data?.limit,
        method: 'GET',
        headers: {
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getApps: builder.query({
      query: data => ({
        url: 'apps?start=1&limit=100',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getCollections: builder.query({
      query: data => ({
        url: 'data_types?start=1&limit=100&app_id=' + data?.app?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getCollectionByID: builder.query({
      query: data => ({
        url: 'data_types/' + data?.activeCollectionID + '?app_id=' + data?.app?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getCollectionData: builder.query({
      query: data => ({
        url: 'data_types/data/' + data?.activeCollectionID +'?app_id=' + data?.app_id + '&start=' + data?.start + '&limit=' + data?.limit,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getTestPromptByID: builder.query({
      query: data => ({
        url: 'prompts/test_gpt3_prompt/' + data?.uuid,
        method: 'GET',
        headers: {
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getFileUploadAccess: builder.query({
      query: data => ({
        url: 'auth/request_upload_access',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getAppByID: builder.query({
      query: data => ({
        url: 'apps/' + data?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
  }),
});

export const {
    useGetAppsQuery,
    useGetAppByIDQuery,
    useGetCollectionByIDQuery,
    useGetCollectionsQuery,
    useGetFileUploadAccessQuery,
    useGetDataTypeDataQuery,
    useGetCollectionDataQuery,
    useIsValidUserMutation,
    useDeleteAppMutation,
    useDeleteCollectionMutation,
    useDeleteCollectionFieldMutation,
    useJoinWaitListMutation,
    useCreateAppMutation,
    useSavePromptMutation,
    useUpdatePromptMutation,
    useFetchPaymentIntentClientSecretMutation,
    useCreateSubscriptionMutation,
    useCancelSubscriptionMutation,
    useGetSubscriptionQuery,
    useGetPaymentIntentQuery,
    useGetPromptsQuery,
    useGetPromptByIDQuery,
    useGetPromptStatsByUserQuery,
    useSendNotificationMutation,
    useRunPredictionMutation,
    useCreateAPPJsonWithCopilotMutation,
    useCreateCollectionMutation,
    useCreateCollectionFieldMutation,
    useSignUpMutation,
    useSaveFileDataMutation,
    useStartFileImportMutation,
    useTestPromptMutation,
    useGetTestPromptByIDQuery,
} = apiSlice;
