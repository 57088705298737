import {createSlice} from '@reduxjs/toolkit';

const platformSlice = createSlice({
    name: 'platform',
    initialState: {
        BASE_URL: undefined,
    },
    reducers: {
        setBaseURL(state, action) {
            const {BASE_URL} = action.payload;
            state.BASE_URL = BASE_URL;
        }
    },
});

export const {
    setBaseURL,
} = platformSlice.actions;
export default platformSlice.reducer;
