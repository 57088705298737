import * as React from "react";
import {Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useIsValidUserMutation} from "../../api/apiSlice";
import {logoutUser} from "../../reducers/loginSlice";

const Tools = () => {
    const {isAuthenticated, user} = useSelector(state => state.login);
    const [validateUser, validateUserResult] = useIsValidUserMutation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!isAuthenticated) {
            window.location.href = '/auth?cta=authenticate';
        } else {
            validateUser({
                "email": user?.email,
                "tokenize": true
            })
        }
    }, [isAuthenticated, user?.email, validateUser]);

    React.useEffect(() => {
       if (validateUserResult?.status === 'fulfilled' && typeof validateUserResult.error !== 'undefined') {
           // logout user
           dispatch(logoutUser());
       }
    });

    return (
        isAuthenticated
        &&
        <div className="px-0 container">
            <Outlet/>
        </div>
    )
}

export default Tools;
