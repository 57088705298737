import * as React from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Marketing = () => {
    const {isAuthenticated} = useSelector((state) => state.login);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (isAuthenticated) {
             navigate('/tools');
        }
    });
    return (
        <>
            {!isAuthenticated ? <Outlet /> : null}
        </>
    );
}

export default Marketing;
