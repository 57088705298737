import Modal from "react-bootstrap/Modal";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleShowPlans} from "../../../reducers/studioSlice";
import {studio_subscription_plans} from "../../../constants";
import Plan from "../../Subscription/Plan";

const PlanOverlay = () => {
    const {showPlans} = useSelector(state => state.studio);
    const dispatch = useDispatch();
    // TODO: Add yearly payment plans shortly

    return (
        <>
            <Modal
                show={showPlans}
                fullscreen={true}
                onHide={() => {
                    dispatch(toggleShowPlans({showPlans: false}));
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Credit Packs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="g-7 g-lg-11 mb-7 row">
                        {
                            studio_subscription_plans.map((plan, index) => {
                                return <Plan key={index} plan={plan} />
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PlanOverlay;
