import {createSlice} from '@reduxjs/toolkit';

const studioSlice = createSlice({
    name: 'studio',
    initialState: {
        showImage: false,
        showPlans: false,
        activeImage: undefined,
        activePrompt: undefined,
    },
    reducers: {
        toggleShowImage(state, action) {
            const {showImage, activeImage, activePrompt} = action.payload;
            state.showImage = showImage;
            state.activeImage = activeImage;
            state.activePrompt = activePrompt;
        },
        toggleShowPlans(state, action) {
            const {showPlans} = action.payload;
            state.showPlans = showPlans;
        }
    },
});

export const {
    toggleShowImage,
    toggleShowPlans
} = studioSlice.actions;
export default studioSlice.reducer;
