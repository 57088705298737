import * as React from 'react';
import StudioHeader from "./StudioHeader";
import FounderLetter from "./FounderLetter";

const StudioLanding = () => {
    return(
        <>
            <StudioHeader />
            <FounderLetter />
        </>
    )
}

export default StudioLanding;
