import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useFetchPaymentIntentClientSecretMutation} from "../../api/apiSlice";
import {setOptions} from "../../reducers/subscriptionSlice";
import CheckoutForm from "./CheckoutForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {STRIPE_PUBLISHABLE_KEY} from "../../constants";
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);


const Subscribe = () => {
    const {plan, tool, options} = useSelector(state => state.subscription);
    const {token} = useSelector(state => state.login);
    const [fetchClientSecret, fetchClientSecretResult] = useFetchPaymentIntentClientSecretMutation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        fetchClientSecret({
            amount: plan?.price*100,
            metadata: {
                plan: plan?.name,
                image_limit: plan?.image_limit,
                tool: tool,
            },
            token: token
        });
    }, [plan, tool, token, fetchClientSecret]);

    React.useEffect(() => {
        if (fetchClientSecretResult?.status === 'fulfilled' && fetchClientSecretResult?.data?.client_secret) {
            dispatch(setOptions({options: {clientSecret: fetchClientSecretResult?.data?.client_secret}}));
        }
    }, [fetchClientSecretResult, dispatch]);

    return (
        <>
            <div className="content container">
                <div className="col-xxl-3 col-sm-6">
                    <div className="pricing-column-title-box mb-sm-5" style={{height: "50px"}}><h3
                        className="mb-2">{plan?.name}</h3>
                    </div>
                    <div className="d-flex align-items-center mb-4"><h3
                        className="display-3 fw-bolder">${plan?.price}</h3></div>
                    <h5 className="mb-4">What’s included</h5>
                    <ul className="fa-ul" style={{faLiMargin: '1.5em'}}>
                        <li className="text-body-secondary mb-2">
                            <span className="fa-li">
                                <svg aria-hidden="true"
                                     focusable="false"
                                     data-prefix="fas"
                                     data-icon="check"
                                     className="svg-inline--fa fa-check text-primary"
                                     role="img"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                                </svg>
                            </span>
                            Generate 150 images, 1 credit per image
                        </li>
                    </ul>
                </div>
                {
                    typeof options?.clientSecret !== "undefined" ? (
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm />
                        </Elements>
                    ) : (
                        <div>Working...</div>
                    )
                }
            </div>
        </>
    );
};

export default Subscribe;
