import * as React from 'react';
import {Field} from "react-final-form";

const PromptForm = ({handleSubmit, form, runPredictionResult, savePromptResult, updatePromptResult}) => {

    return (
        <form onSubmit={handleSubmit}>
            <div className="dropdown mb-3">
                Choose a model: &nbsp;
                <button type="button" className="btn btn-secondary dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false"
                        data-bs-auto-close="outside">
                    {form.getState().values?.model_id}
                </button>
                <div className="dropdown-menu p-4">
                    <h6 className="dropdown-header ps-0 pe-0">Models</h6>
                    <div className="form-check">
                        <label
                            className="form-check-label"
                            htmlFor="stable-diffusion1.5">
                            <Field
                                name="model_id"
                                className="form-check-input"
                                type="radio"
                                component="input"
                                value="stable-diffusion-v1.5"
                            />
                            Stable Diffusion v1.5
                        </label>

                    </div>
                    <div className="form-check">
                        <label
                            className="form-check-label"
                            htmlFor="stable-diffusion-xl-base1.0">
                            <Field
                                name="model_id"
                                className="form-check-input"
                                type="radio"
                                component="input"
                                value="stable-diffusion-xl-base-1.0"
                            />
                            Stable Diffusion XL Base-1.0
                        </label>
                    </div>
                    <div className="form-check">
                        <label
                            className="form-check-label"
                            htmlFor="kandinsky-2.2">
                            <Field
                                name="model_id"
                                className="form-check-input"
                                type="radio"
                                component="input"
                                value="kandinsky-2.2"
                            />
                            Kandinsky 2.2
                        </label>
                    </div>
                </div>
            </div>
            <Field
                name="prompt"
                render={({input, meta}) => (
                    <div className="mt-4">
                        <textarea
                            {...input}
                            className="chat-textarea form-control outline-none border-0 scrollbar resize-none mb-1 p-0 fs-8 fw-normal p-4"
                            placeholder="Prompt"
                            style={{height: "24px !important"}}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSubmit(form.getState().values);
                                }
                            }}
                            disabled={savePromptResult?.isLoading || runPredictionResult?.isLoading || updatePromptResult?.isLoading}
                        />
                    </div>
                )}
            />
        </form>
    )
}

export default PromptForm;
