import * as React from 'react';
import {Outlet} from "react-router-dom";
import {useGetPromptStatsByUserQuery} from "../../api/apiSlice";
import {useSelector} from "react-redux";

const Subscription = () => {
    const {isAuthenticated, token} = useSelector(state => state.login);
    const {
        data: promptStats,
    } = useGetPromptStatsByUserQuery({
        "token": token,
    });

    React.useEffect(() => {
        if (!isAuthenticated) {
            window.location.href = '/auth?cta=authenticate';
        }
    }, [isAuthenticated]);

    return(
        <Outlet context={{promptStats}} />
    )
}

export default Subscription;
