import * as React from "react";

const TopNav = () => {
    return(
        <nav className="px-3 px-lg-7 px-xxl-3 container-small navbar navbar-expand-lg navbar-light"
             style={{zIndex: 1000}}>
            <a className="text-decoration-none flex-1 flex-lg-grow-0 navbar-brand" href="/">
                <div className="d-flex align-items-center">
                    <img src="/assets/img/icons/logo.png" alt="graxle" width={27}/>
                    <p className="logo-text ms-2">Graxle</p>
                </div>
            </a>
            <div className="navbar-collapse collapse">
                <ul className="me-auto mb-2 mb-lg-0 navbar-nav"></ul>
                <div className="d-grid d-lg-flex gap-4 align-items-center"><a
                    className="btn btn-link p-0 text-body order-1 order-lg-0" href="/auth?cta=authenticate">Sign
                    in</a><a className="btn btn-outline-light order-0" href="/auth?cta=register">Sign up</a>
                </div>
            </div>
        </nav>
    )
}

export default TopNav;
