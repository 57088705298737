export const PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL;
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const APP_API_KEY = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const AI_URL = process.env.REACT_APP_AI_URL;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const style = {
    leftPadding: {
        paddingLeft: "5px"
    },
    sideBar: {
        minHeight: "calc(100vh)",
        height: "100%"
    }
};

export const generateFileName = (name) => {
    return name.toLowerCase().replace(/\s/g, "_");
}

export const studio_subscription_plans = [
    {"name": "150 Credit Pack", "price": 19.00, "image_limit": 150},
    {"name": "500 Credit Pack", "price": 39.00, "image_limit": 500},
    {"name": "1000 Credit Pack", "price": 99.00, "image_limit": 1000},
    {"name": "3000 Credit Pack", "price": 299.00, "image_limit": 3000},
]

export const generatePassword = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
    }
    return password;
}

export const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export const capitalize = (string)  => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const parseSelectOptions = (options) => {
    const optionsArr = options.split(",");
    let parsedOption = [];
    for(let i = 0; i < optionsArr.length; i++) {
        parsedOption.push(optionsArr[i].trim());
    }
    return parsedOption;
}

export const default_validation = (values) => {
    let errors = {};
    if (typeof values?.required === "undefined" ) {
        errors.required = "You forgot to check if this property is required."
    }

    if (typeof values?.allow_duplicates === "undefined" ) {
        errors.allow_duplicates = "You forgot to check if this property allows duplicates."
    }

    if (typeof values?.editable === "undefined" ) {
        errors.editable = "You forgot to check if this property is editable."
    }
    return errors;
}

export const allowedHtmlTags = [
    'a',
    'b',
    'blockquote',
    'br',
    'caption',
    'cite',
    'col',
    'colgroup',
    'dd',
    'div',
    'dl',
    'dt',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'i',
    'img',
    'li',
    'ol',
    'p',
    'pre',
    'q',
    'small',
    'span',
    'strike',
    'strong',
    'sub',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'u',
    'ul'
];

export const property_details_headers = (fields=[]) => {
    let headers = ["property"];
    for (let i = 0; i < fields?.length; i++) {
        let keys = Object.keys(fields[i]);
        for (let j = 0; j < keys.length; j++) {
            if (!headers.includes(keys[j]) && !["uuid"].includes(keys[j]) && keys[j] !== "name") {
                headers.push(keys[j]);
            }
        }
    }
    return headers;
};

export const validate_field_name = (field, fields=[]) => {
    let error = undefined;
    for (let i = 0; i < fields?.length; i++) {
        if (fields[i].name === field.name && fields[i].uuid !== field.uuid) {
            error = field?.name + " is already in use."
            break;
        }
    }

    if (!field?.name || field?.name?.trim() === "") {
        error = "You forgot to enter a name."
    }
    return {
        name: error
    };
}

export const bodyObject = (fields=[]) => {
    let body = {};
    for (let i = 0; i < fields?.length; i++) {
        body[fields[i]?.name?.toLowerCase()] = "your_" + fields[i]?.name?.toLowerCase();
    }
    return body;
}
//
// export function validateEmail(email) {
//     let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return re.test(String(email).toLowerCase());
// }

export const emails = [
    {
        subject: "Welcome to Graxle Studio! 🎨",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Welcome to Graxle Studio! I'm excited to have you use Studio.</p>
          <p>Studio empowers you with AI-powered image generation tools to bring your visions to life. Whether you're designing for business or pleasure, I want to help make your creative process seamless and inspiring.</p>
          <p>If you have any questions, please email me at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a>.</p>
          <p>Happy creating!</p>
          <p>Chinedu, Creator of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date().toISOString()
    },
    {
        subject: "Getting Started with Graxle Studio",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Now that you're part of Studio, let's get you started on your creative journey!</p>
          <p>Familiarize yourself with the workspace. Here's how to generate your first image:</p>
          <p>At the top of your dashboard, you'll find a simple input box. Just type in your prompt and hit "Enter" or "Generate." Your custom images will appear below the prompt box in moments, ready for you to download and enjoy.</p>
          <p>Have any questions or feedback? Reach out to me at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a></p>
          <p>Happy designing!</p>
          <p>Best,<br>Chinedu, Creator of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 1 * 60 * 60 * 1000).toISOString() // 1 hour later
    },
    {
        subject: "The Inspiration Behind Graxle Studio",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Curious about the story behind Graxle Studio?</p>
          <p>I created Studio with a passion for merging technology and creativity. I noticed the challenges creators and small business owners face, and I wanted to provide a solution that makes the creative process more accessible and enjoyable.</p>
          <p>Studio’s AI image generator is designed to be a ridiculously easy to use tool that enhances your creativity, giving you the freedom to explore and innovate.</p>
          <p>Thank you for being part of my journey. Email me with suggestions at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a></p>
          <p>Warm regards,<br>Chinedu, Creator of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString() // 1 day later
    },
    {
        subject: "How’s Your Experience with Graxle Studio?",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>I hope you're enjoying your experience with Graxle Studio so far!</p>
          <p>I’d love to hear about your journey and how I can help you make the most out of the platform. If you have any questions or need assistance, don't hesitate to reach out.</p>
          <p>Your feedback is crucial to me. Let me know how I'm doing and what I can improve.</p>
          <p>Looking forward to your response.</p>
          <p>Warm regards,<br>Chinedu, Creator of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString() // 3 days later
    }
];


